import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {IcFavoriteOn} from '../../Atoms/Icons';
import IconButton from './index';

const ICON_SIZES = {
  '2xl': ' h-10 w-10',
  xl: ' h-8 w-8',
  lg: ' h-6 w-6',
  md: ' h-5 w-5',
  sm: ' h-4 w-4',
};

export default function OutlineHeartIconButton({favorite, onClick, size, className, disabled}) {
  const [isHovering, setIsHovered] = useState(false);
  const hoverIn = () => setIsHovered(true);
  const hoverOut = () => setIsHovered(false);
  const [isActive, setIsActive] = useState(false);
  const active = () => setIsActive(true);
  const inactive = () => setIsActive(false);
  const [fillColor, setFillColor] = useState('#E8EDF4');
  const [strokeColor, setStrokeColor] = useState('#182C53');
  useEffect(() => {
    setStrokeColor('#182C53');
    if (!isActive && favorite) setFillColor('#182C53');
    else if (!isActive && !favorite) setFillColor('#E8EDF4');
    else if (isActive && favorite) setFillColor('#E8EDF4');
    else if (isActive && !favorite) setFillColor('#182C53');
    else if (disabled) {
      setFillColor('#e5e7eb');
      setStrokeColor('#9ca3af');
    }
  }, [isActive]);
  useEffect(() => {
    if (!isHovering && isActive) inactive();
  }, [isHovering]);
  return (
    <IconButton
      className={`group rounded-md bg-cold-gray-200 p-2 hover:bg-cold-gray-300 focus:bg-cold-gray-300 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-cold-gray-300 disabled:bg-gray-100  ${className}`}
      onClick={onClick}
      disabled={disabled}
      aria-label="Favorite"
      onMouseDown={active}
      onMouseUp={inactive}
      onMouseEnter={hoverIn}
      onMouseLeave={hoverOut}
      icon={
        <IcFavoriteOn
          variant="multi"
          className={`transition-all ${ICON_SIZES[size]}`}
          fill={fillColor}
          stroke={strokeColor}
        />
      }
    />
  );
}

OutlineHeartIconButton.defaultProps = {
  size: 'lg',
  onClick: e => e,
  favorite: false,
  className: '',
  disabled: false,
};

OutlineHeartIconButton.propTypes = {
  /**
   * This value defines the Icon size. The sizes are the same as Icon component.
   */
  size: PropTypes.oneOf(['2xl', 'xl', 'lg', 'md', 'sm']),
  /**
   * This value defines the favorite status shown
   */
  favorite: PropTypes.bool,
  /**
   * Action performed after element is clicked
   */
  onClick: PropTypes.func,
  /**
   * The optional CSS class to be applied on the Icon button
   */
  className: PropTypes.string,
  /**
   * This value defines if the favorite is disabled
   */
  disabled: PropTypes.bool,
};
