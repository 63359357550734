export function getStageTags(stage) {
  return [
    'stage_tags',
    'device_tags',
    'model_tags',
    'bundle_tags',
    'gender_tags',
    'age_tags',
    'ethnicity_tags',
  ]
    .reduce((current, tag) => [...current, ...stage[tag]], [])
    .map(tag => tag.toLowerCase())
    .join();
}

export default {
  getStageTags,
};
