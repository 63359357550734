import PropTypes from 'prop-types';
import {useEffect, useState, useRef} from 'react';
import {useRouter} from 'next/router';
import {shouldUseStagingData} from '../../../lib/algolia/client';
import {getRandomTemplates, getStageRecommendedStages} from '../../../lib/utils/relatedTemplates';
import HitImage from '../../Discovery/Hit/HitImage';
import HitLink from '../../Discovery/Hit/HitLink';
import Hit from '../../Discovery/Hit';
import MasonryGrid from '../../Discovery/Common/Grid/MasonryGrid';

export default function ProductRelatedTemplates({pdpTags, type}) {
  const router = useRouter();
  const [results, setResults] = useState([]);
  const fetchingRecommendedStages = useRef(false);

  useEffect(() => {
    async function fetchData() {
      if (results.length || fetchingRecommendedStages.current || !pdpTags.pdpId) {
        return;
      }

      fetchingRecommendedStages.current = true;

      const useStagingData = shouldUseStagingData(router);
      let recommendedStages = await getStageRecommendedStages(pdpTags.pdpId, useStagingData);

      if (recommendedStages.length === 0) {
        recommendedStages = await getRandomTemplates(pdpTags, useStagingData);
      }

      setResults(recommendedStages);
      fetchingRecommendedStages.current = false;
    }

    fetchData();
  }, [pdpTags]);

  const COLUMNS = {
    page: {
      sm: 2,
      md: 2,
      lg: 1,
      xl: 2,
      '2xl': 2,
      '3xl': 3,
    },
    modal: {
      sm: 2,
      md: 2,
      lg: 1,
      xl: 2,
      '2xl': 2,
      '3xl': 2,
    },
  };

  const TYPE_CLASS = {
    page: {
      root: '-ml-4 flex px-0 lg:-ml-6',
      column: 'px-0 pl-4 lg:pl-6',
    },
    modal: {
      root: 'flex px-0 gap-4 lg:gap-6',
      column: 'pr-0 lg:pr-0 pl-0 lg:pl-0',
    },
  };

  return (
    <div className={`no-scrollbar max-h-full overflow-y-auto ${type === 'page' ? 'pb-3' : ''}`}>
      <MasonryGrid
        className={TYPE_CLASS[type].root}
        columnClassName={TYPE_CLASS[type].column}
        breakpointColumns={COLUMNS[type]}
        pdpBreakpoints
      >
        {results?.map((hit, position) => (
          <Hit hit={hit} position={position + 1} key={hit.id}>
            <HitLink>
              <HitImage className="mx-auto rounded-md" />
            </HitLink>
          </Hit>
        ))}
      </MasonryGrid>
    </div>
  );
}

ProductRelatedTemplates.defaultProps = {
  pdpTags: null,
  type: 'page',
};
ProductRelatedTemplates.propTypes = {
  pdpTags: PropTypes.shape({
    pdpId: PropTypes.number,
  }),
  /** The type of presentation for the ProductCard, used to set interior dimensions */
  type: PropTypes.oneOf(['modal', 'page']),
};
