import productionClient, {stageSearch, stageStagingSearch, stagingClient} from '../algolia/client';

/**
 * @param {Array} hits - An array of the related templates of the PDP
 * @param {Number} id - ID number of a related template to remove from list
 * @param {Number} numOfHits - the number of hits to return
 * @returns sliced Hits array of related templates
 */
export function randomizeTemplates(hits, id, numOfHits = 12) {
  if (hits.length && id) {
    hits.splice(
      hits.findIndex(item => item.id === id),
      1
    );
  }
  if (hits.length < numOfHits) return hits;

  const rand = Math.random() * hits.length;
  let start = Math.floor(rand);

  if (start + numOfHits > hits.length) {
    start = hits.length - numOfHits;
  }

  return hits.slice(start, start + numOfHits);
}

/**
 * @param {Object} pdpTags - stage tags
 * @param {Number} stageID - ID number of current stage, used to remove from returned array
 * @param {Number} numOfHits - the number of hits to return
 * @returns array of default templates determined  by category
 */
export async function defaultTemplates(pdpTags, stageID, numOfHits = 12, useStagingIndex = false) {
  const searchParams = {
    indexName: 'Stage_production_replica_best_selling',
    hitsPerPage: 50,
    numericFilters: ['is_published=1'],
  };
  let searchMethod = stageSearch;

  if (useStagingIndex) {
    searchParams.indexName = 'Stage_staging_1_replica_best_selling';
    searchMethod = stageStagingSearch;
  }

  if (pdpTags.stageTag?.includes('Apparel')) {
    searchParams.filters = 'stage_tags:POD';
  } else if (pdpTags.stageTag?.includes('Gaming')) {
    searchParams.filters = 'stage_tags:Gaming';
  } else if (pdpTags.categoryName === 'Mockups') {
    searchParams.filters = 'category_name: Mockups';
  } else if (pdpTags.categoryName === 'Design Templates') {
    searchParams.filters = "category_name: 'Design Templates'";
  } else if (pdpTags.categoryName === 'Videos') {
    searchParams.filters = 'template_type: video';
  } else if (pdpTags.categoryName === 'Logos') {
    searchParams.filters = 'category_name: Logos';
  }

  const res = await searchMethod(searchParams);
  const {hits} = res;
  hits.splice(
    hits.findIndex(item => item.id === stageID),
    1
  );

  return hits.slice(0, 0 + numOfHits);
}

/**
 * @param {String} categoryName - 'Mockups', 'Logos', 'Designs', 'Videos'
 * @param {Array} modelTag - ["Model: WMR1","Model: WMD1"...]
 * @param {Array} deviceTag - ["Instagram","Instagram Story Template"...]
 * @param {Array} stageTag - ["Space","Gaming"....]
 * @returns Algolia search params
 */
export function createSearchConfig({categoryName, modelTag, deviceTag, stageTag}, useStagingIndex) {
  let searchParams = {};
  let model = '';
  let indexName = 'Stage_production_replica_best_selling';

  if (modelTag?.length) {
    model = Array.isArray(modelTag) ? `model_tags:${modelTag[0]}` : `model_tags:${modelTag}`;
  } else {
    model = '';
  }

  if (useStagingIndex) {
    indexName = 'Stage_staging_1_replica_best_selling';
  }

  if (categoryName === 'Mockups') {
    searchParams = {
      indexName,
      hitsPerPage: 50,
      filters: `category_name: ${categoryName} AND device_tags:'${
        Array.isArray(deviceTag) ? deviceTag[0] : deviceTag
      }'`,
      facetFilters: model,
      numericFilters: ['is_published=1'],
    };
  } else {
    searchParams = {
      indexName,
      hitsPerPage: 50,
      filters: `category_name: '${categoryName}'`,
      facetFilters: `stage_tags:${stageTag}`,
      numericFilters: ['is_published=1'],
    };
  }
  return searchParams;
}

/**
 * @param {Object} pdpTags
 * @returns array of random templates
 */
export async function getRandomTemplates(pdpTags, useStagingIndex = false) {
  let searchMethod = stageSearch;

  if (useStagingIndex) {
    searchMethod = stageStagingSearch;
  }

  const searchConfig = createSearchConfig(pdpTags, useStagingIndex);
  const res = await searchMethod(searchConfig);
  const {hits} = res;
  let randomTemplates = randomizeTemplates(hits, pdpTags?.pdpId);

  if (hits?.length <= 6) {
    randomTemplates = defaultTemplates(pdpTags, pdpTags?.pdpId, undefined, useStagingIndex);
  }

  return randomTemplates;
}

/**
 * @param {String | Number} stageId - Stage integer ID
 * @returns array of recommended stages
 */
export async function getStageRecommendedStages(stageId, useStagingData = false) {
  let recommendationsApiDomain = 'placeit.net';
  let algoliaClient = productionClient;
  let indexName = 'Stage_production';

  if (useStagingData === true) {
    recommendationsApiDomain = 'staging.placeit.net';
    algoliaClient = stagingClient;
    indexName = 'Stage_staging_1';
  }

  const algoliaIndex = algoliaClient.initIndex(indexName);

  try {
    const response = await fetch(
      `https://${recommendationsApiDomain}/api/v1/stages/${stageId}/recommended_stages`
    );
    const {stages: recommendedStagesIds} = await response.json();

    if (recommendedStagesIds.length === 0) {
      return [];
    }

    const searchResponse = await algoliaIndex.search('', {
      hitsPerPage: recommendedStagesIds.length,
      facetFilters: [recommendedStagesIds.map(id => `id:${id}`)],
    });

    searchResponse.hits.sort((a, b) => {
      return recommendedStagesIds.indexOf(a.id) - recommendedStagesIds.indexOf(b.id);
    });

    return searchResponse.hits;
  } catch (error) {
    console.error(error);
    return [];
  }
}
