import PropTypes from 'prop-types';

const sizes = {
  xl: 'text-[2.5rem]',
  lg: 'text-[2rem]',
  md: 'text-[1.75rem]',
  sm: 'text-2xl',
  xs: 'text-xl',
};

export default function Heading({children, className, size, as}) {
  const ComponentTag = as;

  const textSize = sizes[size] ?? sizes.xl;

  return (
    <ComponentTag
      className={`font-source-sans-pro font-bold leading-tight ${textSize} ${className}`}
      data-testid="heading"
    >
      {children}
    </ComponentTag>
  );
}

Heading.defaultProps = {
  className: '',
  size: 'xl',
  as: 'h1',
};

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['xl', 'lg', 'md', 'sm', 'xs']),
  as: PropTypes.string,
};
