import PropTypes from 'prop-types';
import SelectionBubble from '../UI/Molecules/SelectionBubble';
import Text from '../UI/Atoms/Typography/Text';

/**
 * Creates a container showing Selection bubbles as tags
 */

export default function TagContainer({items, title}) {
  return (
    <div className="flex flex-col">
      {title && (
        <Text
          className="mb-2 text-cold-gray-900 lg:mb-4 lg:text-base"
          size="md"
          as="p"
          weight="bold"
        >
          {title}
        </Text>
      )}
      <div className="mt-0.5 flex w-full flex-wrap gap-4">
        {items?.map(item => {
          return (
            <SelectionBubble
              key={item}
              aria-label="Template Tag"
              className="pointer-events-none truncate py-[6.25px]"
            >
              {item}
            </SelectionBubble>
          );
        })}
      </div>
    </div>
  );
}

TagContainer.displayName = 'TagContainer';

TagContainer.defaultProps = {
  items: [],
  title: '',
};

TagContainer.propTypes = {
  /**
   * Items that will be shown inside of the container
   */
  items: PropTypes.arrayOf(PropTypes.string),
  /**
   * Title of the section
   */
  title: PropTypes.string,
};
