import {niceAssetsCdn} from '../../buckets-cdn';

const assetTypes = {
  image: 'smart_templates',
  video: 'videos',
  expressVideo: 'videos_v5',
};

const isInteractive = record => {
  return (
    record?.text?.some(item => item.allowMove === true || item.allowScale === true) ||
    record?.graphic?.some(item => item.allowMove === true || item.allowScale === true)
  );
};

const buildUrlRecord = (id, type, isStaging) => {
  let baseUrl = niceAssetsCdn.production;

  if (isStaging) {
    baseUrl = niceAssetsCdn.staging;
  }

  return `${baseUrl}/${assetTypes[type]}/${id}/ui.json`;
};

async function getRecord(id, type, isStaging) {
  const url = buildUrlRecord(id, type, isStaging);
  try {
    const record = await fetch(url, {
      method: 'GET',
    }).then(response => response.json());
    return record;
  } catch (error) {
    throw new Error(error);
  }
}

export default async function getProductData(id, type, isStaging = false) {
  try {
    const record = await getRecord(id, type, isStaging);
    return {
      sizes: record?.original?.size?.high || {},
      isInteractive: type === 'image' && isInteractive(record),
    };
  } catch (error) {
    return {
      sizes: {},
      isInteractive: false,
    };
  }
}
