/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
/* eslint-disable import/no-extraneous-dependencies */
import {repeatedElement} from '@plasmicapp/react-web/lib/host';
import HitContext from './HitContext';
import HitTextField from './HitTextField';
import HitImage from './HitImage';
import HitLink from './HitLink';
import algoliaInsights from '../../../lib/algolia/insights';

// TODO: rename to HitProvider and merge with HitContext
export default function Hit({className, hit, position, openModal, children}) {
  const onClick = event => {
    openModal(event);
    algoliaInsights.sendStageClickEvent(hit.id, position);
  };
  return (
    <div onClick={onClick} data-id={hit.id} href={hit.stage_link} role="button" tabIndex={0}>
      <HitContext.Provider className={className} value={hit}>
        {repeatedElement(hit.id, children)}
      </HitContext.Provider>
    </div>
  );
}

Hit.TextField = HitTextField;
Hit.Image = HitImage;
Hit.Link = HitLink;

Hit.defaultProps = {
  className: null,
  hit: {},
  position: 1,
  children: null,
  openModal: () => {},
};

Hit.propTypes = {
  className: PropTypes.string,
  hit: PropTypes.shape({
    id: PropTypes.number,
    stage_link: PropTypes.string,
  }),
  children: PropTypes.node,
  openModal: PropTypes.func,
  /**
   * Position of the item
   */
  position: PropTypes.number,
};
