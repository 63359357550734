import PropTypes from 'prop-types';
import {WithProductData} from './ProductProvider';
import Heading from '../UI/Atoms/Typography/Heading';

/* eslint-disable camelcase */
export function Title({product: {seo_title}}) {
  return (
    <div role="heading" aria-level="4">
      <Heading className="line-clamp-2 text-cold-gray-900" size="xs" as="h1">
        {seo_title}
      </Heading>
    </div>
  );
}
Title.propTypes = {
  product: PropTypes.shape({
    seo_title: PropTypes.string,
  }).isRequired,
};

export const ProductTitle = WithProductData(Title);

export const PlasmicData = {
  name: 'ProductTitle',
  props: {},
  importPath: './components/PDP/ProductTitle',
  isDefaultExport: true,
};
