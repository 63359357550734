import PropTypes from 'prop-types';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import dynamic from 'next/dynamic';
import StageRecord from '../../types/StageRecord';
import {IcShare02} from '../../UI/Atoms/Icons';
import Text from '../../UI/Atoms/Typography/Text';
import {SHARE_MODAL_OPENED, SOCIAL_SHARE, trackEvent} from '../../../lib/analytics';

export default function ProductShareButton({product, classNames}) {
  const Clipboard = dynamic(() => import('./Clipboard'));
  const Facebook = dynamic(() => import('./Facebook'));
  const Twitter = dynamic(() => import('./Twitter'));
  const Pinterest = dynamic(() => import('./Pinterest'));
  const LinkedIn = dynamic(() => import('./LinkedIn'));
  const stagePath = product?.stage_link;

  async function nativeShare() {
    const shareData = {
      title: product.seo_title,
      text: product.stage_description,
      url: window.location.origin + stagePath,
    };
    console.log(product);
    try {
      await navigator.share(shareData);
      trackEvent(SOCIAL_SHARE, {channel: 'native mobile'});
    } catch (err) {
      console.error(err);
    }
  }

  const handleOpen = open => {
    if (open) {
      trackEvent(SHARE_MODAL_OPENED);
    }
  };

  return (
    <DropdownMenu.Root modal={false} onOpenChange={handleOpen}>
      <DropdownMenu.Trigger
        className={`rounded-md data-[state=open]:bg-cold-gray-400 ${classNames.trigger}`}
      >
        <IcShare02 className={`fill-cold-gray-800 ${classNames.icon}`} />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          sideOffset={8}
          align="start"
          className={`flex h-[80px] w-[138px] flex-col gap-1 rounded-md bg-white py-2 shadow-xl lg:h-[188px] ${classNames.content}`}
        >
          <Twitter stage={product} />
          <Facebook path={stagePath} />
          <Pinterest stage={product} />
          <LinkedIn path={stagePath} />
          <button
            className="flex w-full gap-2   px-3 py-1.5 lg:hidden"
            type="button"
            onClick={nativeShare}
          >
            <IcShare02 className="h-5 w-5 fill-cold-gray-800" />
            <Text size="md" className="text-cold-gray-700">
              Share via...
            </Text>
          </button>
          <Clipboard path={stagePath} />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

ProductShareButton.propTypes = {
  classNames: PropTypes.shape({
    trigger: PropTypes.string,
    icon: PropTypes.string,
    content: PropTypes.string,
  }),
  product: StageRecord.isRequired,
};

ProductShareButton.defaultProps = {
  classNames: {
    trigger: '',
    icon: '',
    content: '',
  },
};
