import PropTypes from 'prop-types';
import Text from '../Atoms/Typography/Text';

/**
 * Creates a ribbon to display content
 */
export default function Ribbon({children, disabled, className}) {
  return (
    <Text
      className={`rounded-3xl bg-black/50 px-3 py-1 text-white shadow-sm [&[data-disabled='true']]:bg-gray-300 [&[data-disabled='true']]:text-gray-200 ${className}`}
      size="lg"
      as="span"
      weight="semibold"
      aria-label="Ribbon"
      data-disabled={disabled}
    >
      {children}
    </Text>
  );
}

Ribbon.displayName = 'Ribbon';

Ribbon.defaultProps = {
  disabled: false,
  className: '',
};

Ribbon.propTypes = {
  /**
   * Content of the ribbon
   */
  children: PropTypes.node.isRequired,
  /**
   * The optional value to show the ribbon with disabled styles
   */
  disabled: PropTypes.bool,
  /**
   * The optional CSS classes to be applied on the ribbon
   */
  className: PropTypes.string,
};
