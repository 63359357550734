import PropTypes from 'prop-types';
import React from 'react';

const SIZES = {
  sm: 'text-sm p-2',
  md: 'text-base py-2 px-6',
  lg: 'text-lg py-2 px-6',
  'landing-page': 'text-base sm:text-lg py-0.5 px-3',
};

const VARIANTS = {
  white:
    'bg-white text-cold-gray-800 focus:border-cold-gray-600 hover:bg-cold-gray-300 active:text-white active:bg-cold-gray-800 disabled:text-gray-300 disabled:bg-gray-100',
  'cold-gray':
    'bg-cold-gray-200 text-cold-gray-800 focus:border-cold-gray-600 hover:bg-cold-gray-300 active:text-white active:bg-cold-gray-800 disabled:text-cold-gray-300 disabled:bg-cold-gray-100',
  'landing-page':
    'bg-white text-cold-gray-700 shadow-2xl focus:outline focus:outline-offset-2 focus:outline-2 focus:outline-cold-gray-600 hover:bg-cold-gray-100 active:text-white active:bg-secondary-900 disabled:text-cold-gray-400 disabled:bg-cold-gray-100',
  active: 'text-white bg-secondary-900 shadow-2xl outline-none ',
};

const SelectionBubble = React.forwardRef(function SelectionBubble(
  {children, className, size, variant, as, active, ...props},
  ref
) {
  const ComponentTag = as;
  const colorStyles = active ? VARIANTS.active : VARIANTS[variant];

  return (
    <ComponentTag
      className={`select-none rounded-3xl border-2 border-transparent font-source-sans-pro font-semibold leading-tight transition-colors hover:cursor-pointer disabled:cursor-default ${SIZES[size]} ${colorStyles} ${className}`}
      ref={ref}
      {...props}
    >
      {children}
    </ComponentTag>
  );
});

SelectionBubble.displayName = 'SelectionBubble';

SelectionBubble.defaultProps = {
  className: '',
  size: 'sm',
  variant: 'white',
  as: 'span',
  active: false,
};

SelectionBubble.propTypes = {
  /**
   * Content of the selection bubble
   */
  children: PropTypes.node.isRequired,
  /**
   * The optional CSS classes to be applied on the selection bubble
   */
  className: PropTypes.string,
  /**
   * Size of the content
   */
  size: PropTypes.oneOf(Object.keys(SIZES)),
  /**
   * Color palette for the selection bubble
   */
  variant: PropTypes.oneOf(['cold-gray', 'white', 'landing-page']),
  /**
   * Tag element used to display the selection bubble
   */
  as: PropTypes.string,
  /**
   * Value to show the active styles
   */
  active: PropTypes.bool,
};

export default SelectionBubble;
