import PropTypes from 'prop-types';
import Ribbon from '../UI/Molecules/Ribbon';
import {WithProductData} from './ProductProvider';

const VISIBILITY = {
  true: 'block',
  false: 'hidden',
};

export function ProductRibbon({product}) {
  return (
    <div className={`mb-0 w-full ${VISIBILITY[product.is_free]}`}>
      <Ribbon className="inline-block px-[11px] py-px text-[15.75px] lg:px-3 lg:py-0 lg:text-lg">
        FREE
      </Ribbon>
    </div>
  );
}

ProductRibbon.displayName = 'ProductRibbon';
ProductRibbon.defaultProps = {
  product: {
    is_free: false,
  },
};
ProductRibbon.propTypes = {
  /**
   * Product data
   */
  product: PropTypes.shape({
    /**
     * Flag to indicate if the template is free
     */
    is_free: PropTypes.bool,
  }),
};

const ProductRibbonWithProduct = WithProductData(ProductRibbon);

export default ProductRibbonWithProduct;

export const PlasmicData = {
  name: 'ProductRibbon',
  props: {},
  importPath: './components/PDP/ProductRibbon',
  isDefaultExport: true,
};
