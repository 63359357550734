import {cloneElement, useContext} from 'react';
import PropTypes from 'prop-types';
import HitContext from './HitContext';

export default function HitTextField({className, targetField, children}) {
  const hit = useContext(HitContext);
  const content = hit[targetField];
  if (children) {
    return cloneElement(children, {className}, content);
  }
  return (
    <div data-testid="hit-textfield" className={className}>
      {content}
    </div>
  );
}

HitTextField.defaultProps = {
  className: null,
  targetField: 'seo_title',
  children: null,
};

HitTextField.propTypes = {
  className: PropTypes.string,
  targetField: PropTypes.string,
  children: PropTypes.element,
};

export const PlasmicProps = {
  name: 'HitTextField',
  importPath: './components/Discovery/Hit/HitTextField',
  isDefaultExport: true,
  props: {
    children: 'slot',
    targetField: {
      type: 'string',
      defaultValue: 'seo_title',
    },
  },
};
