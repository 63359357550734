import PropTypes from 'prop-types';
import OutlineHeartIconButton from '../UI/Molecules/IconButton/OutlineHeartIconButton';

export default function ProductFavoriteButton({favorite, onClick}) {
  return (
    <OutlineHeartIconButton
      className="flex h-[38px] w-[38px] p-[0.438rem] sm:bg-white lg:h-8 lg:w-8 lg:p-1"
      size="lg"
      favorite={favorite}
      onClick={onClick}
    />
  );
}

ProductFavoriteButton.defaultProps = {
  favorite: false,
  onClick: e => e,
};

ProductFavoriteButton.propTypes = {
  /**
   * This value defines the favorite status shown
   */
  favorite: PropTypes.bool,
  /**
   * Action will be executed when the button is clicked
   */
  onClick: PropTypes.func,
};

export const PlasmicData = {
  name: 'ProductFavoriteButton',
  props: {},
  importPath: './components/PDP/ProductFavoriteButton',
  isDefaultExport: true,
};
