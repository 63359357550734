import {useContext} from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import HitContext from './HitContext';

import useBucketsCdn from '../../../hooks/useBucketsCdn';

export default function HitImage({className}) {
  const hit = useContext(HitContext);
  const {stageThumbUrl} = useBucketsCdn();

  return (
    <Image
      className={className}
      alt={hit.name}
      src={stageThumbUrl(hit.large_thumb_path)}
      width={hit.large_thumb_width}
      height={hit.large_thumb_height}
    />
  );
}

HitImage.defaultProps = {
  className: '',
};

HitImage.propTypes = {
  className: PropTypes.string,
};

export const PlasmicProps = {
  name: 'HitImage',
  importPath: './components/Discovery/Hit/HitImage',
  isDefaultExport: true,
  props: {},
};
