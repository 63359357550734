import PropTypes from 'prop-types';
import {forwardRef} from 'react';

/**
 - Can't be created without an icon
 - Icon instance defines the button size
 */

const IconButton = forwardRef(({icon, className, ...props}, ref) => {
  return (
    <button ref={ref} type="button" className={className} {...props}>
      {icon}
    </button>
  );
});

IconButton.displayName = 'IconButton';
IconButton.propTypes = {
  /**
   - Must be an instance of Icon
   */
  icon: PropTypes.node.isRequired,

  /**
   * The optional CSS class to be applied on the button
   */
  className: PropTypes.string,
};

IconButton.defaultProps = {
  className: '',
};

export default IconButton;
