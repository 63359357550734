import {useEffect, useState} from 'react';
import favoritesList from '../lib/placeit/favorites';

/**
 * Hook to use the Favorites List
 *
 * @returns {Array<Number>} Favorites List items
 */
const useFavorites = () => {
  const [favorites, setFavorites] = useState(favoritesList.get());

  useEffect(() => favoritesList.subscribe(setFavorites), []);

  return favorites;
};

export default useFavorites;
