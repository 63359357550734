import React, {createContext, useContext, useEffect, useMemo, useState, useCallback} from 'react';
import {useRouter} from 'next/router';
import {useHits} from 'react-instantsearch';
import PropTypes from 'prop-types';
import {productTypes} from '../../lib/constants';
import getProductData from '../../lib/placeit/services/stages';
import {isBrowser} from '../../lib/utils/common';
import {shouldUseStagingData} from '../../lib/algolia/client';

const ProductContext = createContext({});

const hasTransparency = data => {
  return data.stage_tags?.includes('Transparent') || data.has_sibling;
};

const isBundle = data => {
  return data.bundle_tags?.length > 0;
};

function useDeepCompareMemoize(value) {
  const ref = React.useRef();
  const isEqual = JSON.stringify(ref.current) === JSON.stringify(value);

  if (!isEqual) {
    ref.current = value;
  }

  return ref.current;
}

export function ProductProvider({children}) {
  const [externalData, setExternalData] = useState({});
  const hits = useHits();
  const firstHit = useDeepCompareMemoize(hits.hits[0]);
  const router = useRouter();
  const useStagingData = shouldUseStagingData(router);

  const {sizes, isInteractive} = externalData;
  const memoizedHasTransparency = useCallback(data => hasTransparency(data), []);
  const memoizedIsBundle = useCallback(data => isBundle(data), []);

  const hit = useMemo(() => {
    if (!firstHit) {
      return {};
    }

    return {
      ...firstHit,
      sizes,
      isInteractive,
      hasTransparency: memoizedHasTransparency(firstHit),
      isBundle: memoizedIsBundle(firstHit),
    };
  }, [firstHit, sizes, isInteractive, memoizedHasTransparency, memoizedIsBundle]);

  const {id, smart_template_id: smartTemplateId, image_type_id: imageTypeId} = hit;

  useEffect(() => {
    if (!smartTemplateId) {
      return;
    }

    if (isBrowser()) {
      window.pdpStageId = id;
    }

    const templateId = typeof smartTemplateId === 'string' ? smartTemplateId : smartTemplateId[0];

    getProductData(templateId, productTypes[imageTypeId], useStagingData)
      .then(data => {
        setExternalData(data);
      })
      .catch(defaultData => {
        setExternalData(defaultData);
      });
  }, [smartTemplateId, id, imageTypeId]);

  // here we would instrument hit with a mock if Plasmic is in preview mode

  return <ProductContext.Provider value={hit}>{children}</ProductContext.Provider>;
}

export const WithProductData = Component => {
  function WithProductDataComponent() {
    const product = useProduct();
    return <Component product={product} />;
  }
  return WithProductDataComponent;
};

export function useProduct() {
  return useContext(ProductContext);
}

ProductProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

ProductProvider.defaultProps = {
  children: null,
};
