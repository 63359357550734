import PropTypes from 'prop-types';
import {createContext, useContext, useMemo} from 'react';
import favoritesList, {createFavorite, deleteFavorite} from '../lib/placeit/favorites';
import {UserContext} from './UserContext';
import {STAGE_BOOKMARKED, STAGE_UNBOOKMARKED, EMAIL_BOOKMARKED, trackEvent} from '../lib/analytics';

export const FavoritesContext = createContext({
  addFavorite: () => null,
  removeFavorite: () => null,
});

/**
 * Creates the Provider to use the FavoritesContext
 *
 * @returns {FavoritesProvider} FavoritesProvider instance
 */
export function FavoritesProvider({children}) {
  const {currentUser, ssoModalOpener} = useContext(UserContext);

  const addFavorite = stage => {
    if (!currentUser?.loggedIn || currentUser?.loggedIn === 'loading') {
      ssoModalOpener();
      return;
    }
    const props = {
      stage: stage.id,
      tags: stage.stage_tags?.join(','),
    };

    createFavorite(stage.id);
    trackEvent(STAGE_BOOKMARKED, props);
    trackEvent(EMAIL_BOOKMARKED, {...props, stage_found: window.location.href});
  };

  const removeFavorite = stage => {
    if (!currentUser?.loggedIn || currentUser?.loggedIn === 'loading') {
      ssoModalOpener();
      return;
    }
    const props = {
      stage: stage.id,
      tags: stage.stage_tags?.join(','),
    };
    deleteFavorite(stage.id);
    trackEvent(STAGE_UNBOOKMARKED, props);
  };

  const getItemsWithFavorites = items => {
    const currentFavorites = favoritesList.get();
    return items?.map(item => {
      const isFavorite = currentFavorites.some(element => element === item.id);
      const favoriteAction = isFavorite
        ? () => {
            removeFavorite(item);
          }
        : () => {
            addFavorite(item);
          };
      return {
        ...item,
        is_favorite: isFavorite,
        favorite_action: favoriteAction,
      };
    });
  };

  const value = useMemo(
    () => ({
      addFavorite,
      removeFavorite,
      getItemsWithFavorites,
    }),
    [currentUser, ssoModalOpener]
  );

  return <FavoritesContext.Provider value={value}>{children}</FavoritesContext.Provider>;
}

FavoritesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Hook to use the FavoritesContext
 *
 * @returns {FavoritesContext} FavoritesContext instance
 */
export default function useFavoritesContext() {
  const context = useContext(FavoritesContext);

  if (context === undefined) {
    throw new Error('useFavoritesContext must be used within a FavoritesProvider');
  }

  return context;
}
