import {useContext} from 'react';
import PropTypes from 'prop-types';
import HitContext from './HitContext';

export default function HitLink({className, targetField, children}) {
  const hit = useContext(HitContext);
  const url = hit[targetField];
  return (
    <a className={className} href={url}>
      {children}
    </a>
  );
}

HitLink.defaultProps = {
  className: '',
  targetField: 'stage_link',
  children: null,
};

HitLink.propTypes = {
  className: PropTypes.string,
  targetField: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export const PlasmicProps = {
  name: 'HitLink',
  importPath: './components/Discovery/Hit/HitLink',
  isDefaultExport: true,
  props: {
    children: 'slot',
    targetField: {
      type: 'string',
      defaultValue: 'stage_link',
    },
  },
};
